define("ember-metrics/metrics-adapters/google-analytics-four", ["exports", "@ember/debug", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom", "ember-metrics/metrics-adapters/base"], function (_exports, _debug, _objectTransforms, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GoogleAnalyticsFour extends _base.default {
    toStringExtension() {
      return 'GoogleAnalyticsFour';
    }
    install() {
      const {
        id,
        options
      } = this.config;
      (false && !(id) && (0, _debug.assert)(`[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`, id));
      this.options = {
        send_page_view: true,
        ...options
      };
      this._injectScript(id);
      window.dataLayer = window.dataLayer || [];
      this.gtag('js', new Date());
      this.gtag('config', id, (0, _objectTransforms.compact)(this.options));
    }
    _injectScript(id) {
      let script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
      document.head.appendChild(script);
    }
    gtag() {
      window.dataLayer.push(arguments);
      return arguments;
    }
    trackEvent(options = {}) {
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        event
      } = compactedOptions;
      if (!event) {
        return;
      }
      delete compactedOptions.event;
      return this.gtag('event', event, compactedOptions);
    }
    trackPage(options = {}) {
      if (this.options.send_page_view) {
        return;
      }
      if (options?.page && !options?.page_location) {
        options.page_location = options?.page;
        delete options.page;
      }
      if (options?.title && !options?.page_title) {
        options.page_title = options?.title;
        delete options.title;
      }
      return this.trackEvent({
        event: 'page_view',
        ...options
      });
    }
    uninstall() {
      (0, _removeFromDom.default)('script[src*="gtag/js"]');
      delete window.dataLayer;
    }
  }
  _exports.default = GoogleAnalyticsFour;
});