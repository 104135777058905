define("ember-modal-dialog/components/tether-dialog", ["exports", "@ember-decorators/component", "@ember/object", "@ember/string", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/tether-dialog"], function (_exports, _component, _object, _string, _basicDialog, _tetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let TetherDialog = _exports.default = (_dec = (0, _component.layout)(_tetherDialog.default), _dec2 = (0, _object.computed)('targetAttachment'), _dec(_class = (_class2 = class TetherDialog extends _basicDialog.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "targetAttachment", null);
      _defineProperty(this, "attachment", null);
      _defineProperty(this, "tetherTarget", null);
    }
    init() {
      super.init(...arguments);
      this._ensureAttachments();
    }
    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${(0, _string.dasherize)(targetAttachment)} emd-target-attachment-${(0, _string.dasherize)(targetAttachment)}`;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this._ensureAttachments();
    }
    // element, css selector, view instance, 'viewport', or 'scroll-handle'

    get tetherClassPrefix() {
      return 'ember-tether';
    }
    set tetherClassPrefix(val) {
      if (val) {
        return val;
      }
      return 'ember-tether';
    }

    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in
    _ensureAttachments() {
      if (!this.attachment) {
        (0, _object.set)(this, 'attachment', 'middle center');
      }
      if (!this.targetAttachment) {
        (0, _object.set)(this, 'targetAttachment', 'middle center');
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "targetAttachmentClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "targetAttachmentClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tetherClassPrefix", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "tetherClassPrefix"), _class2.prototype)), _class2)) || _class);
});