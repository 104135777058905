define("ember-composable-helpers/index", ["exports", "ember-composable-helpers/helpers/chunk"], function (_exports, _chunk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ChunkHelper", {
    enumerable: true,
    get: function () {
      return _chunk.default;
    }
  });
});