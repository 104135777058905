define("ember-element-helper/helpers/element", ["exports", "@ember/component/helper", "@ember/debug", "@ember/component", "@embroider/util"], function (_exports, _helper, _debug, _component, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // eslint-disable-next-line ember/no-classic-components
  function UNINITIALIZED() {}
  class ElementHelper extends _helper.default {
    constructor() {
      super(...arguments);
      this.tagName = UNINITIALIZED;
      this.componentClass = null;
    }
    compute(params, hash) {
      (false && !(params.length === 1) && (0, _debug.assert)('The `element` helper takes a single positional argument', params.length === 1));
      (false && !(Object.keys(hash).length === 0) && (0, _debug.assert)('The `element` helper does not take any named arguments', Object.keys(hash).length === 0));
      let tagName = params[0];
      if (tagName !== this.tagName) {
        this.tagName = tagName;
        if (typeof tagName === 'string') {
          this.componentClass = (0, _util.ensureSafeComponent)(class DynamicElement extends _component.default {
            constructor(...args) {
              super(...args);
              _defineProperty(this, "tagName", tagName);
            } // eslint-disable-line ember/require-tagless-components
          }, this);
        } else {
          this.componentClass = null;
          (0, _debug.runInDebug)(() => {
            let message = 'The argument passed to the `element` helper must be a string';
            try {
              message += ` (you passed \`${tagName}\`)`;
            } catch (e) {
              // ignore
            }
            (false && !(tagName === undefined || tagName === null) && (0, _debug.assert)(message, tagName === undefined || tagName === null));
          });
        }
      }
      return this.componentClass;
    }
  }
  _exports.default = ElementHelper;
});