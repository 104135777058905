define("ember-power-select-with-create/components/power-select-multiple-with-create", ["exports", "ember-power-select-with-create/components/power-select-with-create", "@ember/object", "@embroider/util", "ember-power-select/components/power-select-multiple"], function (_exports, _powerSelectWithCreate, _object, _util, _powerSelectMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PowerSelectMultipleWithCreate = _exports.default = (_class = class PowerSelectMultipleWithCreate extends _powerSelectWithCreate.default {
    get powerSelectComponent() {
      return (0, _util.ensureSafeComponent)(this.args.powerSelectComponent || _powerSelectMultiple.default, this);
    }
    selectOrCreate(selection, select) {
      let suggestion = selection.filter(option => {
        return option.__isSuggestion__;
      })[0];
      if (suggestion) {
        this.args.onCreate(suggestion.__value__, select);
      } else {
        this.args.onChange(selection, select);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "selectOrCreate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectOrCreate"), _class.prototype)), _class);
});