define("ember-power-select-with-create/components/power-select-with-create/suggested-option", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{@option.text}}
  
  */
  {
    "id": "OOkvGsfR",
    "block": "[[[1,[30,1,[\"text\"]]],[1,\"\\n\"]],[\"@option\"],false,[]]",
    "moduleName": "ember-power-select-with-create/components/power-select-with-create/suggested-option.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});